<template>
  <div class="container">
    <h1>Профиль</h1>
    <div class="page-section">
      <b-form
        @submit.prevent="formSubmit"
        v-if="Object.keys(profile).length != 0"
      >
        <div class="form-group">
          <p>
            Баланс:
            <span v-if="profile.balance"> {{ profile.balance.balance }}</span>
            <span v-else>0</span>
            рублей.
          </p>
          <b-form-group label="Сумма пополнения" label-for="name">
            <b-form-input
              v-model="paymentInitiateSum"
              type="number"
            ></b-form-input>
          </b-form-group>

          <b-button
            :disabled="!paymentInitiateSum"
            variant="primary"
            @click="paymentInitiate"
            >Пополнить</b-button
          >
        </div>

        <div class="form-group">
          <label for="loadVideo">Видео</label>

          <div v-if="profile._videoPresentationInUpload" class="mb-4">
            <video
              :src="profile._videoPresentationInUpload"
              controls="controls"
              class="mw-100"
            ></video>
          </div>
          <div class="row">
            <div class="col-md-auto">
              <p>Заменить</p>
              <input type="file" ref="fileInput" @change="handleFileChange" />
            </div>
            <div class="col-md-6" v-if="selectedFile">
              <b-button variant="primary" @click="uploadVideo"
                >Загрузить видео</b-button
              >
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <b-form-group label="Название продавца" label-for="name">
              <b-form-input
                id="name"
                v-model="profile.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Контактный телефон" label-for="contactPhone">
              <b-form-input
                id="contactPhone"
                v-model="profile.contactPhone"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Регион проживания" label-for="inn">
              <b-form-select v-model="profile.region">
                <b-form-select-option
                  :value="item"
                  v-for="(item, index) in liveRegions"
                  :key="index"
                >
                  {{ item }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-md-6">
            <b-form-group label="Тип">
              <b-form-select
                v-model="profile.legalState"
                :options="optionsLegalState"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group :label="legalStateValueTitle" label-for="inn">
              <b-form-input
                id="inn"
                v-model="profile.inn"
                type="text"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
        <div class="form-group">
          <label for="lawAddress">Адрес магазина</label>
          <div class="row">
            <div class="col-md-6">
              <b-form-input
                id="lawAddress"
                v-model="profile.lawAddress"
                type="text"
                class="mb-2"
              ></b-form-input>
            </div>
            <div class="col-md-6">
              <SelectOnMap :lat-lng="profilelatLng" @update="updateCoords" />
            </div>
          </div>
        </div>

        <b-form-group>
          <b-form-checkbox v-model="profile.getAnotherRegionOrders" switch>
            Получать заказы с других регионов
          </b-form-checkbox>
          <b-form-checkbox v-model="notification" switch>
            Получать уведомления
          </b-form-checkbox>
        </b-form-group>

        <div class="my-4">
          <h5>Настройки фильтров запросов</h5>
          <b-form-group label="Типы запчастей">
            <b-form-checkbox-group v-model="typesDetailsChecked">
              <b-form-checkbox value="Old">Контактная</b-form-checkbox>
              <b-form-checkbox value="New">Новая</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group label="Регионы автомобилей">
            <b-form-checkbox-group v-model="regionsChecked" stacked>
              <b-form-checkbox
                :value="region.id"
                v-for="region in regions"
                :key="region.id"
                >{{ region.name }}</b-form-checkbox
              >
            </b-form-checkbox-group>
          </b-form-group>
        </div>
        <b-button type="submit" variant="success" :disabled="!profileModified"
          >Сохранить</b-button
        >
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

import SelectOnMap from "../components/SelectOnMap";

export default {
  name: "profile",
  components: {
    SelectOnMap,
  },

  data() {
    return {
      notification: true,
      typesDetailsAll: ["Old", "New"],
      typesDetailsChecked: [],
      regionsChecked: [],
      optionsLegalState: [
        { value: "Private", text: "Частное лицо" },
        { value: "Individual", text: "ИП" },
        { value: "Legal", text: "Юридическое лицо" },
      ],
      selectedFile: null,
      paymentInitiateSum: 0,
    };
  },
  async mounted() {
    await this.fetchProfile();
    await this.fetchRegions();
    this.fetchLiveRegions();

    this.typesDetailsAll.forEach((element) => {
      if (this.profile["filter"].disabledTypes.indexOf(element) == -1) {
        this.typesDetailsChecked.push(element);
      }
    });

    this.regions.forEach((element) => {
      if (this.profile["filter"].disabledRegions.indexOf(element.id) == -1) {
        this.regionsChecked.push(element.id);
      }
    });

    this.notification = this.profile.pushToken ? true : false;

    if (this.$route.query.paymentSuccess) {
      this.$bvToast.toast(`Оплата прошла успешно`, {
        title: "Успешно",
        autoHideDelay: 5000,
        variant: "success",
      });
    }
  },
  computed: {
    ...mapGetters(["profile", "savedProfile", "regions", "liveRegions"]),

    profilelatLng() {
      return [this.profile.lat, this.profile.long];
    },

    profileModified() {
      return !_.isEqual(this.profile, this.savedProfile);
    },

    legalStateValueTitle() {
      if (this.profile) {
        switch (this.profile.legalState) {
          case "Private":
            return "ИНН";
          case "Individual":
            return "ОГРИП";
          case "Legal":
            return "ОГРН";
          default:
            return "";
        }
      }
    },
  },

  methods: {
    ...mapActions([
      "fetchProfile",
      "changeProfile",
      "fetchRegions",
      "fetchLiveRegions",
    ]),

    updateCoords(arr) {
      this.profile.lat = arr[0];
      this.profile.long = arr[1];
    },

    async formSubmit() {
      let res = await this.changeProfile();
      if (res) {
        this.$bvToast.toast(`Пользователь успешно изменен`, {
          title: "Сохранено",
          autoHideDelay: 5000,
          variant: "success",
        });
      } else {
        this.$bvToast.toast(`Что-то пошло не так. Попробуйте ещё раз`, {
          title: "Ошибка",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async paymentInitiate() {
      const url = await this.$store.dispatch(
        "paymentInitiate",
        this.paymentInitiateSum
      );

      if (url) {
        location.href = url;
      } else {
        this.$bvToast.toast(`Что-то пошло не так. Попробуйте ещё раз`, {
          title: "Ошибка",
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },

    async handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },

    async uploadVideo() {
      const onSendProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Загрузка: ${percentCompleted}%`);
      };

      await this.$store.dispatch("uploadPresentationVideo", {
        video: this.selectedFile,
        onSendProgress,
      });
    },
  },

  watch: {
    typesDetailsChecked: function (val) {
      this.profile.filter.disabledTypes = [];
      this.typesDetailsAll.forEach((element) => {
        if (val.indexOf(element) == -1) {
          this.profile.filter.disabledTypes.push(element);
        }
      });
    },
    regionsChecked: function (val) {
      this.profile.filter.disabledRegions = [];
      this.regions.forEach((element) => {
        if (val.indexOf(element.id) == -1) {
          this.profile.filter.disabledRegions.push(element.id);
        }
      });
    },
    notification: function (val) {
      this.profile.pushToken = val ? this.savedProfile.auth : "";
    },
  },
};
</script>
