<template>
  <div>
    <b-button @click="modalShow = true">Выбрать точку на карте</b-button>
    <b-modal v-model="modalShow" size="lg" centered>
      <template #modal-title>
        <div class="text-dark">Укажите адрес магазина</div>
      </template>
      <div class="map">
        <l-map
          ref="mymap"
          style="height: 500px; width: 100%"
          :zoom="zoom"
          :center="center"
          @update:zoom="zoomUpdated"
          @update:center="centerUpdated"
          :noBlockingAnimations="true"
          :options="{ zoomControl: false }"
          @click="clickOnMap"
        >
          <l-tile-layer :url="url"></l-tile-layer>
          <l-control-zoom position="topright"></l-control-zoom>
          <l-marker
            :lat-lng="newLatLng"
            :draggable="true"
            @update:latLng="updateNewLatLng"
          >
          </l-marker>
        </l-map>
      </div>
      <template #modal-footer>
        <b-button
          @click="saveCoords"
          variant="success"
          :disabled="needConfirmCoords"
          >Подтвердить</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControl,
  LControlZoom,
} from "vue2-leaflet";
import _ from "lodash";

export default {
  name: "selectOnMap",
  props: {
    latLng: Array,
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControl,
    LControlZoom,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      center: [53.328248, 108.837283],
      zoom: 6,
      modalShow: false,
      newLatLng: [53.328248, 108.837283],
    };
  },
  computed: {
    needConfirmCoords() {
      return _.isEqual(this.newLatLng, this.latLng);
    },
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    clickOnMap(event) {
      this.newLatLng = [event.latlng.lat, event.latlng.lng];
    },

    initNewLatLng() {
      if (this.latLng[0] && this.latLng[1]) {
        this.newLatLng = [...this.latLng];
        this.center = [...this.latLng];
      }
    },

    updateNewLatLng(event) {
      this.newLatLng = [event.lat, event.lng];
    },

    saveCoords() {
      this.$emit("update", [...this.newLatLng]);
      this.modalShow = false;
    },
  },

  mounted() {},

  watch: {
    modalShow: function (value) {
      if (value) {
        setTimeout(() => {
          this.$refs.mymap.mapObject.invalidateSize();
        }, 100);
      }

      if (!value) {
        this.initNewLatLng();
      }
    },
    latLng: function (value) {
      this.initNewLatLng();
    },
  },
};
</script>

<style lang="scss" scoped></style>
